import React from "react";
import { IoFileTray, IoTicketSharp } from "react-icons/io5";
import Files from "views/admin/tables/Files/Files";
import Users from "views/admin/tables/Users/Users";
import ListHistory from "views/ListHistory";
import ListUpload from "views/ListUpload";
import Optimizer from "views/Optimizer";


const routes = [
  {
    name: "Users",
    layout: "/admin",
    icon: <IoTicketSharp className="h-6 w-6" />,
    path: "Users",
    component: <Users />,
  },  {
    name: "Files",
    layout: "/admin",
    icon: <IoFileTray className="h-6 w-6" />,
    path: "Files",
    component: <Files />,
  },
  {
    name: "Optimizer",
    layout: "/admin",
    icon: <IoFileTray className="h-6 w-6" />,
    path: "Optimizer",
    component: <Optimizer />,
  },
  {
    name: "Upload List",
    layout: "/admin",
    icon: <IoFileTray className="h-6 w-6" />,
    path: "ListUpload",
    component: <ListUpload />,
  },
  {
    name: "List History",
    layout: "/admin",
    icon: <IoFileTray className="h-6 w-6" />,
    path: "ListHistory",
    component: <ListHistory />,
  },
];
export default routes;
