
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row, Table, Pagination, Modal, Button, Form } from 'react-bootstrap';
import { FiUpload } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import { getFirestore, onSnapshot, doc } from 'firebase/firestore';
import { app } from './firebase';
import Swal from 'sweetalert2';
// import { io } from 'socket.io-client';
import io from 'socket.io-client';
import {  AiOutlineDownload } from "react-icons/ai";
import { MdOutlineSearch } from "react-icons/md";
import { IoIosArrowDropup } from "react-icons/io";
import { IoIosArrowDropdown } from "react-icons/io";

import { debounce } from 'lodash';
import "./upload.css"
const db = getFirestore(app);

function ListHistory() {
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage] = useState(10);
    const [showModal, setShowModal] = useState(false);
    const [selectedFileId, setSelectedFileId] = useState('');
    // const [fileType, setFileType] = useState('');
    const [showWarning, setShowWarning] = useState(false);
    const [searchDate, setSearchDate] = useState('');
    const [searchTitle, setSearchTitle] = useState('');

    const navigate = useNavigate();
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short' };
    const [expandedRows, setExpandedRows] = useState({});

    useEffect(() => {
        const uid = localStorage.getItem("user");
        if (!uid) {
            navigate('/SignIn');
            return;
        }
        const filesRef = doc(db, "userProfiles", uid);



        const unsubscribe = onSnapshot(filesRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                const data = docSnapshot.data();
                const features = data.features?.rawList ; 

                const sortedFiles = Object.entries(data.files).map(([id, file]) => ({
                    id,
                    ...file
                })).sort((a, b) => {
                    return new Date(b.startTime) - new Date(a.startTime);
                });
                const combinedData = sortedFiles.map(file => ({
                    ...file,
                    features 
                }));
                
                setTableData(combinedData);
            } else {
                setTableData([]);
            }
        });

        const systemDocRef = doc(db, "dashBoard", "system");
        const unsubscribeSystem = onSnapshot(systemDocRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                if (docSnapshot.data().pennyskips === false && docSnapshot.data().titanskip === false || docSnapshot.data().maintenance === true) {
                    setShowWarning(true);
                } else {
                    setShowWarning(false);
                }
            }
        });


        return () => {
            unsubscribe()
            unsubscribeSystem();

        };
    }, [navigate]);

    const handleDownloadClick = (fileId, rowIndex) => {
        toggleRow(rowIndex);

        setSelectedFileId(fileId);
    };
    const handleFileTypeSelection = (Type) => {
        const downloadUrl = `https://api.v2.badskip.com/skiptracing/download?fileId=${selectedFileId}&fileType=${Type}`;
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = `${selectedFileId}.csv`;
        a.click();

        setShowModal(false);
    };

    const toggleRow = (rowIndex) => {
        setExpandedRows((prev) => ({
            ...prev,
            [rowIndex]: !prev[rowIndex],
        }));
    };
    const filteredData = tableData?.filter(file => {
        const titleMatch = file?.fileName?.toLowerCase()?.includes(searchTitle?.toLowerCase());
        const dateMatch = searchDate ? new Date(file.startTime)?.toISOString()?.slice(0, 10) === searchDate : true;
        return titleMatch && dateMatch;
    });

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredData?.slice(indexOfFirstRow, indexOfLastRow);
    const totalPages = Math.ceil(filteredData?.length / rowsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderPagination = () => {
        const pageNumbers = [];
        const maxPagesToShow = 8;
        let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
        let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

        if (endPage - startPage < maxPagesToShow - 1) {
            startPage = Math.max(1, endPage - maxPagesToShow + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                    {i}
                </Pagination.Item>
            );
        }

        return (
            <Pagination>
                <Pagination.Prev disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)} />
                {pageNumbers}
                <Pagination.Next disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)} />
            </Pagination>
        );
    };
    const [isSending, setIsSending] = useState(false);
    const [lastReceivedBatch, setLastReceivedBatch] = useState(null);

    useEffect(() => {
        const URL = 'https://api.v2.badskip.com/';
        const socket = io(URL);

        socket.on('connect', () => {
            console.log('Connected to socket server');
        });
        const handleProgress = debounce((data) => {
            if (data.fileId && data.batch !== lastReceivedBatch && !isSending) {
                setLastReceivedBatch(data.batch); // Update the last processed batch
                setIsSending(true);

                setTableData((prevTableData) => {
                    return prevTableData.map((file) =>
                        file?.id === data.fileId ? { ...file, progress: data.progress } : file
                    );
                });
                setIsSending(false);

            }
        }, 1000);

        socket.on('progress', handleProgress);

        return () => {
            socket.off('progress', handleProgress);
            socket.disconnect();
        };
    }, [lastReceivedBatch, isSending]);

    return (
        <>
              <div className='uploadnew historylist'>
        
        <section className='listUpload w-100'>
                <Container>
             
                    {currentRows.length === 0 ?
                        <>
                            <div className='uploadDiv my-5'>
                                <img src="../images/Group (5).png" alt="upload-list" />
                                <h3>No List Uploaded</h3>
                                <p>Upload a List to Start The Process</p>
                            </div>
                        </> :

                        <Row className='my-1 tableShowList'>
                            <Col md={12} sm={12}>
                                <div className='table-responsive'>
                                    <Table hover className='w-100' >
                                        <thead>
                                            <tr>
                                                {/* <th>UUID</th> */}
                                                <th>Name</th>
                                                <th>Date</th>
                                                <th>Status</th>

                                            

                                                <th>Download</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentRows?.map((row, rowIndex) => (
                                                <>
                                                    <tr key={rowIndex} className={expandedRows[rowIndex] && 'selectedTr'}>
                                                        <td className='nameTd'>
                                                        {row.status === "Completed" && (
                                                            expandedRows[rowIndex] ? <IoIosArrowDropdown onClick={() => handleDownloadClick(row.id, rowIndex)}  className='dropicon' />: <IoIosArrowDropup onClick={() => handleDownloadClick(row.id, rowIndex)} className='dropicon'/> )}

                                                        <p>{row.fileName}</p></td>
                                                        <td>{
                                                            row.startTime  }
                                                            {/* row.startTime ? new Date(row.startTime).toLocaleString('en-US', options) : 'Loading ...' */}
                                                      </td>
                                                        <td >
                                                        {/* <div className={row.status=='In Progress' && row.progress ? 'tdprogress':null}> */}
                                                        <span className={`spanStatus ${row.status}`}>   {row.status}</span> 
                                                        
                                                        {row.status=='In Progress' && row.progress ? 
                                                        <div className="progress-container">
                                                            <span className="progress-text">{row.progress}</span>
                                                            <div className="progress-bar">
                                                                <div 
                                                                className="progress-fill" 
                                                                style={{
                                                                    width: `${(parseInt(row?.progress?.split('/')[0]) / parseInt(row?.progress?.split('/')[1])) * 100}%`
                                                                }}
                                                                />
                                                            </div>
                                                            </div>
                                                            :null} 
                                                            {/* </div> */}
                                                        </td>


                                                        <td>
                                                            {/* <button onClick={() => toggleRow(rowIndex)} className="expandBtn">
                                                                {expandedRows[rowIndex] ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
                                                            </button> */}
                                                            {row.status === "Completed" && (
                                                                <button onClick={() => handleDownloadClick(row.id, rowIndex)} className='downloadBtn'>
                                                                    <AiOutlineDownload />
                                                                </button>
                                                            )}
                                                        </td>

                                                    </tr>
                                                    {expandedRows[rowIndex] && (
                                                        <>
                                                            {/* <tr>
                                                                <td colSpan="5">
                                                                    <div className="expanded-content">
                                                                        <strong>Additional Details:</strong>
                                                                        <p>Record ID: {row.id}</p>
                                                                        <p>Start Time: {new Date(row.startTime).toLocaleString()}</p>
                                                                    </div>
                                                                </td>
                                                            </tr> */}
                                                            <tr className='differ'>
                                                                <td colSpan="2">
                                                                    File Name
                                                                </td>
                                                                <td colSpan="1"></td>
                                                                <td colSpan="1">
                                                                    Actions

                                                                </td>
                                                            </tr>
                                                            <tr className='rowdownload selectedTr'>
                                                                <td colSpan="2">
                                                                    Cold Calling Optimized - Primary
                                                                </td>
                                                                <td colSpan="1">

                                                                </td>
                                                                <td colSpan="1">
                                                                    <button className="downloadBtn" onClick={() => handleFileTypeSelection('primaryUrl')}>
                                                                        <AiOutlineDownload />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr className='rowdownload selectedTr'>
                                                                <td colSpan="2">
                                                                    Cold Calling Optimized - Secondary
                                                                </td>
                                                                <td colSpan="1">

                                                                </td>
                                                                <td colSpan="1">
                                                                    <button className="downloadBtn" onClick={() => handleFileTypeSelection('secondaryUrl')}>

                                                                        <AiOutlineDownload />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr className='rowdownload selectedTr'>
                                                                <td colSpan="2">
                                                                    SMS Marketing Optimized
                                                                </td>
                                                                <td colSpan="1">

                                                                </td>
                                                                <td colSpan="1">
                                                                    <button className="downloadBtn" onClick={() => handleFileTypeSelection('textingOptimizeUrl')}>

                                                                        <AiOutlineDownload />
                                                                    </button>
                                                                </td>
                                                        
                                                            </tr>
                                                            {row.features == true  && row.rawUrl == true ?                                                    
                             
                                                                <tr className='rowdownload selectedTr'>
                                                                <td colSpan="2">
                                                                Raw List Optimized
                                                                </td>
                                                                <td colSpan="1">

                                                                </td>
                                                                <td colSpan="1">
                                                                    <button className="downloadBtn" onClick={() => handleFileTypeSelection('rawUrl')}>

                                                                        <AiOutlineDownload />
                                                                    </button>
                                                                </td>
                                                        
                                                            </tr>
                                                                :null}
                                                        </>
                                                    )}
                                                </>
                                            ))}
                                        </tbody>
                                    </Table>


                                </div>
                                <Row className='paginationTable'>
                                 
                                    <Col md={3}>
                                        {renderPagination()}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }
                </Container>
            </section>


            {/* <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Download Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex justify-content-between flex-wrap'>
                        <button className="buttonDownload m-2" onClick={() => handleFileTypeSelection('primaryUrl')}>
                            Cold Calling Optimized - Primary

                        </button>
                        <button className=" buttonDownload m-2" onClick={() => handleFileTypeSelection('secondaryUrl')}>
                            Cold Calling Optimized - Secondary

                        </button>
                        <button className=" buttonDownload m-2" onClick={() => handleFileTypeSelection('textingOptimizeUrl')}>
                            SMS Marketing Optimized
                        </button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>

                </Modal.Footer>
            </Modal> */}
            </div>
        </>
    );
}

export default ListHistory;
