/* eslint-disable */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";

export function Links(props) {
  let location = useLocation();
  const { routes, onClose, isMobileView } = props;

  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const handleLinkClick = () => {
    if (isMobileView) {
      onClose();
    }
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (
        route.layout === "/admin" ||
        route.layout === "/auth" ||
        route.layout === "/"
      ) {
        return (
          <Link
            key={index}
            to={route.layout + "/" + route.path}
            onClick={handleLinkClick}
          >
            <div className="relative flex hover:cursor-pointer">
              <li
                className="flex cursor-pointer listnav"
                key={index}
              >
                <span
                  className={`${
                    activeRoute(route.path) === true
                      ? "font-bold text-brand-500 dark:text-white"
                      : "font-medium text-gray-600"
                  }`}
                >
                  {route.icon ? route.icon : <DashIcon />}{" "}
                </span>
                <p
                  className={`leading-1 flex ${
                    activeRoute(route.path) === true
                      ? "font-bold text-navy-700 dark:text-white"
                      : "font-medium text-gray-600"
                  }`}
                >
                  {route.name}
                </p>
              </li>
              {activeRoute(route.path) ? (
                <div className="absolute top-px h-9 w-1 rounded-lg bg-brand-500 end-0 dark:bg-brand-400" />
              ) : null}
            </div>
          </Link>
        );
      }
      return null;
    });
  };

  return createLinks(routes);
}

export default Links;
