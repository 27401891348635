import React, { useEffect, useRef, useState } from "react";
import { HiX } from "react-icons/hi";
import Links from "./components/Links"; 
import SidebarCard from "components/sidebar/componentsrtl/SidebarCard";
import { IoFileTray, IoFileTraySharp, IoTicketSharp } from "react-icons/io5";
import Users from "views/admin/tables/Users/Users";
import { FiPaperclip, FiUpload, FiUsers } from "react-icons/fi";
import Files from "views/admin/tables/Files/Files";
import ListUpload from "views/ListUpload";
import ListHistory from "views/ListHistory";
import Optimizer from "views/Optimizer";
import { RiFilePaper2Fill } from "react-icons/ri";


const routes = [
  {
    name: "Users",
    layout: "/admin",
    icon: <FiUsers className="h-6 w-6" />,
    path: "Users",
    component: <Users />,
  },
  {
    name: "Files",
    layout: "/admin",
    icon: <IoFileTray className="h-6 w-6" />,
    path: "Files",
    component: <Files />,
  }, {
    name: "Optimizer",
    layout: "/admin",
    icon: <FiUpload className="h-6 w-6" />,
    path: "Optimizer",
    component: <Optimizer />,
  },
  // {
  //   name: "Upload List",
  //   layout: "/admin",
  //   icon: <IoFileTray className="h-6 w-6" />,
  //   path: "ListUpload",
  //   component: <ListUpload />,
  // },
  {
    name: "List History",
    layout: "/admin",
    icon: <RiFilePaper2Fill className="h-6 w-6" />,
    path: "ListHistory",
    component: <ListHistory />,
  },

];

const Sidebar = ({ open, onClose }) => {
  const sidebarRef = useRef(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 900);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMobileView && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose, isMobileView]);

  return (
    <div
      ref={sidebarRef}
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[56px] mt-[50px] flex items-center`}>
        <div className="mt-1 ml-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white">
          Bad<span className="font-medium">Skip</span>
        </div>
      </div>
      <div className="mt-[58px] mb-7 h-px bg-gray-300 dark:bg-white/30" />

      <ul className="">
        <Links routes={routes} onClose={onClose} isMobileView={isMobileView} />
      </ul>

      <div className="flex justify-center">
        <SidebarCard />
      </div>

    </div>
  );
};

export default Sidebar;
