import React, { useState, useEffect } from "react";
import Card from "./components/card";
import logo from "./assets/img/icon2.png";
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut
} from "https://www.gstatic.com/firebasejs/10.8.1/firebase-auth.js";
import { initializeApp } from "https://www.gstatic.com/firebasejs/10.8.1/firebase-app.js";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "bad-skip-db.firebaseapp.com",
  projectId: "bad-skip-db",
  storageBucket: "bad-skip-db.appspot.com",
  messagingSenderId: "593615586665",
  appId: "1:593615586665:web:6f35a89a608aade58c69f8"
};
const allowedUid = process.env.REACT_APP_UIUID;
const allowedUidUser1 = process.env.REACT_APP_UIUID1;
const allowedUidUser2 = process.env.REACT_APP_UIUID2;
const allowedUidUser3 = process.env.REACT_APP_UIUID3;


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const user = localStorage.getItem("user");

    if (user == allowedUid ) {
      window.location.href = "/admin/Users";
    }
  }, []);

  const handleSignIn = async (e) => {
    e.preventDefault();

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      if (user.uid == allowedUid) {
        localStorage.setItem("user", user.uid);
        window.location.href = "/admin/Users";

      }

      else {
        await signOut(auth);
        setError("You are not authorized to sign in.");
      }

    } catch (error) {
      setError("Invalid email or password. Please try again.");
      console.error("Error signing in:", error);
    }
  };

  return (
    <Card className="cardSign mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center">
      <div className="max-w-50 mt-[10vh] w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <form onSubmit={handleSignIn}>
          <img src={logo} alt="Logo" />

          <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
            Sign In
          </h4>
          <p className="mb-9 ml-1 text-base text-gray-600">
            Enter your email and password to sign in!
          </p>

          <div className="mb-6 flex items-center gap-3">
            <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
            <p className="text-base text-gray-600 dark:text-white"></p>
            <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          </div>

          <input
            variant="auth"
            extra="mb-3"
            label="Email*"
            placeholder="mail@simmmple.com"
            id="email"
            type="text"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            variant="auth"
            extra="mb-3"
            label="Password*"
            placeholder="Min. 8 characters"
            id="password"
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          {error && <p className="mb-4 text-red-500">{error}</p>}

          <button className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Sign In
          </button>
        </form>
      </div>
    </Card>
  );
}
