import React, { useState, useEffect } from "react";
import { getFirestore, collection, onSnapshot, doc, setDoc, getDoc, getDocs } from "firebase/firestore";
import Card from "components/card";
import { useNavigate } from "react-router-dom";
import { Pagination } from 'react-bootstrap';
import { Modal, Button, Form } from 'react-bootstrap';
import { db } from "../../../firebase"
import Swal from "sweetalert2";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [toggleState, setToggleState] = useState({
    maintenance: false,
    pennyskips: false,
    titanskip: false,
  });
  const [credits, setCredits] = useState(0);
  const [titanCredits, setTitanCredits] = useState(0);
  const [pennyCredits, setPennyCredits] = useState(0);

  const [userCredits, setUserCredits] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newCredits, setNewCredits] = useState(0);
  const navigate = useNavigate("");
  const apiKey = process.env.REACT_APP_API_KEY1;
  const fetchUserProfiles = async () => {
    try {
      const snapshot = await getDocs(collection(db, "userProfiles"));
      const usersArray = [];
      let totalUserCredits = 0;

      snapshot.forEach((doc) => {
        const userData = { id: doc.id, ...doc.data() };
        usersArray.push(userData);

        if (
          [process.env.REACT_APP_UIUID, process.env.REACT_APP_UIUID2, process.env.REACT_APP_UIUID3, process.env.REACT_APP_UIUID4].includes(userData.id)
        ) {
          totalUserCredits += userData.credits || 0;
        }
      });

      setUsers(usersArray);
      setUserCredits(totalUserCredits);
    } catch (error) {
      console.error("Error fetching user profiles: ", error);
    }
  };

  useEffect(() => {
    const userId = localStorage.getItem("user");

    if (userId !== process.env.REACT_APP_UIUID) {
      navigate("/");
      return;
    }

 
    fetchUserProfiles();

    const docRef = doc(db, "dashBoard", "system");
    const unsubscribeToggles = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        setToggleState(doc.data());
      }
    });

    const docRef2 = doc(db, "dashBoard", "systemCredits");
    const unsubscribeToggles2 = onSnapshot(docRef2, (doc) => {
      if (doc.exists()) {
        setCredits(doc.data().credits);
        setPennyCredits(doc.data().pennyCredits);
        setTitanCredits(doc.data().titanCredits);

      }
    });

    return () => {
      unsubscribeToggles();
      unsubscribeToggles2();
    };
  }, [db, navigate]);

  // const handleToggle = async (category) => {
  //   const newState = !toggleState[category];
  //   const newToggleState = { ...toggleState, [category]: newState };

  //   if (category === "titanskip" && newState) {
  //     newToggleState.pennyskips = false;
  //     newToggleState.maintenance = false;
  //   } else if (category === "pennyskips" && newState) {
  //     newToggleState.titanskip = false;
  //     newToggleState.maintenance = false;
  //   } else if (category === "maintenance" && newState) {
  //     newToggleState.pennyskips = false;
  //     newToggleState.titanskip = false;
  //   } else if (!newToggleState.titanskip && !newToggleState.pennyskips) {
  //     newToggleState.maintenance = true;
  //   }

  //   setToggleState(newToggleState);
  //   await setDoc(doc(getFirestore(), "dashBoard", "system"), newToggleState);
  // };
  const handleToggle = async (category) => {
    const newState = !toggleState[category];
    const newToggleState = { ...toggleState, [category]: newState };
  
    setToggleState(newToggleState);
  
    // Only send the changed category and its new state
    const changedToggle = { [category]: newState };
  
// console.log(newToggleState);

    // if (category === "titanskip" && newState) {
    //   newToggleState.pennyskips = false;
    //   newToggleState.maintenance = false;
    // } else if (category === "pennyskips" && newState) {
    //   newToggleState.titanskip = false;
    //   newToggleState.maintenance = false;
    // } else if (category === "maintenance" && newState) {
    //   newToggleState.pennyskips = false;
    //   newToggleState.titanskip = false;
    // } else if (!newToggleState.titanskip && !newToggleState.pennyskips) {
    //   newToggleState.maintenance = true;
    // }

    
// console.log(changedToggle);
  
    try {
     const response = await fetch("https://api.v2.badskip.com/system/updateStatus", {
        method: "POST",
        headers: { "Content-Type": "application/json", 'x-api-key': apiKey },
        body: JSON.stringify(changedToggle),

      });
// console.log(response);

    } catch (error) {
      console.error(error);
    }
  };

  // const [currentPage, setCurrentPage] = useState(1);
  // const [rowsPerPage] = useState(9);
  // const indexOfLastRow = currentPage * rowsPerPage;
  // const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  const filteredUsers = users.filter((user) => {
    return (
      (user.email && user.email.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (user.companyEmail && user.companyEmail.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  });


  // const currentRows = filteredUsers.slice(indexOfFirstRow, indexOfLastRow);
  // const totalPages = Math.ceil(filteredUsers.length / rowsPerPage);

  // const handlePageChange = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };

  // const renderPagination = () => {
  //   const pageNumbers = [];
  //   const maxPagesToShow = 8;
  //   let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
  //   let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

  //   if (endPage - startPage < maxPagesToShow - 1) {
  //     startPage = Math.max(1, endPage - maxPagesToShow + 1);
  //   }

  //   for (let i = startPage; i <= endPage; i++) {
  //     pageNumbers.push(
  //       <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
  //         {i}
  //       </Pagination.Item>
  //     );
  //   }

  //   return (
  //     <Pagination>
  //       <Pagination.Prev disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)} />
  //       {pageNumbers}
  //       <Pagination.Next disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)} />
  //     </Pagination>
  //   );
  // };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setNewCredits(user.credits);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedUser(null);
  };

  const handleSave = async () => {
    if (selectedUser) {
      try {
        const response = await fetch("https://api.v2.badskip.com/system/addCredits", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'x-api-key': apiKey
          },
          body: JSON.stringify({ amount: newCredits, uid: selectedUser.id }),
        });
  
        if (response.ok) {
          
          handleClose();
         fetchUserProfiles();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  // const handleSave = async () => {
  //   if (selectedUser) {
  //     const userRef = doc(db, "userProfiles", selectedUser.id);
  //     const currentCredits = selectedUser.credits || 0; 

  //     const updatedCredits = currentCredits + newCredits;

  //     await setDoc(userRef, { credits: updatedCredits }, { merge: true });

  //     const systemCreditsRef = doc(db, "dashBoard", "systemCredits"); 
  //     const systemCreditsSnapshot = await getDoc(systemCreditsRef); 

  //     if (systemCreditsSnapshot.exists()) {
  //       const systemCreditsData = systemCreditsSnapshot.data();

  //       const currentSystemCredits = systemCreditsData.credits || 0; 

  //       const updatedSystemCredits = currentSystemCredits + newCredits;

  //       await setDoc(systemCreditsRef, { credits: updatedSystemCredits }, { merge: true });
  //     }

  //     handleClose();
  //     // window.location.reload()
  //   }
  // };

  useEffect(() => {
    if (showModal) {
      const bootstrapLink = document.createElement('link');
      bootstrapLink.rel = 'stylesheet';
      bootstrapLink.href = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css';
      document.head.appendChild(bootstrapLink);

      return () => {
        document.head.removeChild(bootstrapLink);
      };
    }
  }, [showModal]);
  const remainingCredits = credits ? credits - userCredits : 0;

  return (
    <>
      <div className="mt-5">
        <Card extra="w-full pb-10 p-4 h-full">
          <header className="">
            <div className="flex flexToggle">
              <div>
                <label className="flex items-center">
                  <span className="mr-2">Maintenance</span>
                  <input
                    type="checkbox"
                    className="toggle"
                    checked={toggleState.maintenance}
                    onChange={() => handleToggle("maintenance")}
                  />
                </label>
              </div>
              <div>
                <label className="flex items-center">
                  <span className="mr-2">Penny Skips</span>
                  <input
                    type="checkbox"
                    className="toggle"
                    checked={toggleState.pennyskips}
                    onChange={() => handleToggle("pennyskips")}
                  />
                </label>
              </div>
              <div>
                <label className="flex items-center">
                  <span className="mr-2">Titan Skips</span>
                  <input
                    className="toggle"
                    type="checkbox"
                    checked={toggleState.titanskip}
                    onChange={() => handleToggle("titanskip")}
                  />
                </label>
              </div>
            </div>
            <div className="flex justify-between">
              <h5 className="my-6"> Total Credits : ({remainingCredits ? remainingCredits : 0}) </h5>
              <h5 className="my-6"> Titan Credits : ({titanCredits ? titanCredits : 0}) </h5>

              <h5 className="my-6"> Penny Credits : ({pennyCredits ? pennyCredits : 0}) </h5>

            </div>
            <input
              type="text"
              placeholder="Search by email"
              className="border p-2 w-full"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </header>

          <div className="mt-8 overflow-x-auto">
            <table className="w-full whitespace-nowrap">
              <thead>
                <tr className="bg-gray-100 dark:bg-gray-800">
                  <th className="py-4 px-8 ">User Id</th>

                  <th className="py-4 px-8 ">Name</th>
                  <th className="py-4 px-8 ">Email</th>
                  <th className="py-4 px-8 ">Phone</th>
                  <th className="py-4 px-8 ">Credits</th>
                  <th className="py-4 px-8 ">Add Credits</th>
                  <th className="py-4 px-8 ">Company Name</th>
                  <th className="py-4 px-8 ">Website</th>

                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user) => (
                  <tr key={user.id} className="border-b dark:border-gray-700">
                    {user.type === "callcenter" ? (
                      <>
                        <td className="py-4 px-8">{user.id}</td>

                        <td className="py-4 px-8">{user.principalFirstName} {user.principalLastName}</td>
                        <td className="py-4 px-8">{user.companyEmail}</td>
                        <td className="py-4 px-8">{user.principalPhoneNumber}</td>
                        <td className="py-4 px-8">{user.credits}</td>
                        <td className="py-4 px-8">
                          <button variant="primary" onClick={() => handleEdit(user)}>
                            Add
                          </button>
                        </td>
                        <td className="py-4 px-8">{user.companyName}</td>
                        <td className="py-4 px-8">{user.companyWebsiteOrSocialMedia}</td>

                      </>
                    ) : (
                      <>
                        <td className="py-4 px-8">{user.id}</td>

                        <td className="py-4 px-8">{user.firstName} {user.lastName}</td>
                        <td className="py-4 px-8">{user.email}</td>
                        <td className="py-4 px-8">{user.phoneNumber}</td>
                        <td className="py-4 px-8">{user.credits}</td>
                        <td className="py-4 px-8">
                          <button variant="primary" onClick={() => handleEdit(user)}>
                            Add
                          </button>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>


          </div>
          <br />


          {/* {renderPagination()} */}

          <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Credits for {selectedUser?.email}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formNewCredits">
                  <Form.Label>Credits</Form.Label>
                  <Form.Control
                    type="number"
                    // value={newCredits}
                    onChange={(e) => setNewCredits(Number(e.target.value))}
                    placeholder="Enter new credits"
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <button onClick={handleSave}>
                Save
              </button>
            </Modal.Footer>
          </Modal>
        </Card>
      </div>
    </>
  );
};

export default Users;
